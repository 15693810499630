export const trackUserEvent = (parameters) => {
    if (!window.dataLayer) return;
    window.dataLayer.push({
        event: 'userEvent',
        ...parameters,
    });
};

export const trackPerformance = (trackerName, startTime, endTime) => {
    if (!window.dataLayer) return;

    const duration = Math.round(endTime - startTime);

    window.dataLayer.push({
        event: 'performanceMetric',
        tracker: trackerName,
        duration: duration
    });
}

export const createPerformanceTracker = (trackerName) => {
    const startTime = performance.now();

    return {
        end: () => {
            const endTime = performance.now();
            trackPerformance(trackerName, startTime, endTime);
        }
    };
};