import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import emitter from '../eventEmitter';

const Term = ({ term, definitionEn, definition, explanation }) => {
    const { i18n } = useTranslation();
    const [showTooltip, setShowTooltip] = useState(false);
    const [isPositioned, setIsPositioned] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({
        left: '50%',
        transform: 'translateX(-50%)',
        top: '-0.5rem',
        placement: 'top',
        position: 'absolute',
        maxHeight: 'none',
    });
    const tooltipRef = useRef(null);
    // Just to a trick to create unique ids, looking like term-{9-random-chars}
    const termId = useRef(`term-${Math.random().toString(36).slice(2, 11)}`);

    useEffect(() => {
        const handleTooltipEvent = (id) => {
            if (id !== termId.current && showTooltip) {
                setShowTooltip(false);
            }
        };

        // Emitting an event to close tooltips from other Term components
        emitter.on('term-tooltip-toggle', handleTooltipEvent);
        return () => emitter.off('term-tooltip-toggle', handleTooltipEvent);
    }, [showTooltip]);

    const handleTooltipToggle = () => {
        const newShowTooltip = !showTooltip;
        setShowTooltip(newShowTooltip);

        if (newShowTooltip) {
            emitter.emit('term-tooltip-toggle', termId.current);
        }
    };

    // Make sure the tooltip is positioned correctly, not overflowing the viewport
    useEffect(() => {
        if (showTooltip && tooltipRef.current) {
            setIsPositioned(false);

            const updatePosition = () => {
                const tooltipWidth = tooltipRef.current.offsetWidth;
                const tooltipHeight = tooltipRef.current.offsetHeight;
                const viewportHeight = window.innerHeight;

                // in sidepanel?
                const sidepanelParent = tooltipRef.current.closest('.sidepanel-content-wrap');

                const containerWidth = sidepanelParent
                    ? sidepanelParent.offsetWidth
                    : window.innerWidth;
                const containerLeft = sidepanelParent
                    ? sidepanelParent.getBoundingClientRect().left
                    : (document.getElementById('chat-control-panel')?.getBoundingClientRect().right || 0);

                const elementRect = tooltipRef.current.parentElement.getBoundingClientRect();
                const elementCenterX = (elementRect.left + (elementRect.width / 2)) - containerLeft;
                const HORIZONTAL_BUFFER = 5;
                const VERTICAL_BUFFER = 75;
                const MAX_HEIGHT_RATIO = 0.9;

                let left = '50%';
                let transform = 'translateX(-50%)';
                let top = '-0.5rem';
                let placement = 'top';
                let position = 'absolute';
                let maxHeight = 'none';
                let overflow = 'visible';

                const tooltipLeft = elementCenterX - (tooltipWidth / 2);
                const tooltipRight = elementCenterX + (tooltipWidth / 2);

                const tooltipTop = elementRect.top - tooltipHeight - VERTICAL_BUFFER;
                const tooltipBottom = elementRect.bottom + tooltipHeight + VERTICAL_BUFFER;
                const exceedsViewport = tooltipBottom > viewportHeight;

                if (tooltipTop < 0 && !exceedsViewport) {
                    top = '100%';
                    placement = 'bottom';
                } else if (exceedsViewport) {
                    position = 'fixed';
                    top = '50%';
                    transform = 'translate(-50%, -50%)';
                    maxHeight = `${viewportHeight * MAX_HEIGHT_RATIO}px`;
                    overflow = 'auto';
                    placement = 'center';
                }

                if (!exceedsViewport) {
                    if (tooltipLeft < HORIZONTAL_BUFFER) {
                        left = `calc(50% + ${-(tooltipLeft - HORIZONTAL_BUFFER)}px)`;
                    } else if (tooltipRight > containerWidth) {
                        left = `calc(50% - ${tooltipRight - (containerWidth - HORIZONTAL_BUFFER)}px)`;
                    }
                }

                setTooltipPosition({
                    left,
                    transform,
                    top,
                    placement,
                    position,
                    maxHeight,
                    overflow,
                });

                // Small delay to allow position to be resolved before displaying the popup
                setTimeout(() => { setIsPositioned(true); }, 10);
            };

            updatePosition();
        } else {
            setIsPositioned(false);
        }
    }, [showTooltip]);

    const showLocal = i18n.language !== 'en-US';

    return (
        <span
            data-te="true"
            data-tc="Chat"
            data-ta="Term toggle (click)"
            className="term relative dashed-underline-blue cursor-pointer"
            onClick={() => {
                if (!window.getSelection().toString()) {
                    handleTooltipToggle();
                }
            }}>
            {term}
            {showTooltip && (
                <span
                    ref={tooltipRef}
                    className={`term-details font-normal text-gray-800 px-6 py-4 bg-white rounded-md shadow-xl z-[60] w-max max-w-xs cursor-default overflow-y-auto ${isPositioned ? 'opacity-100' : 'opacity-0'}`}
                    style={{
                        ...tooltipPosition,
                        transform: `${tooltipPosition.transform} ${tooltipPosition.placement === 'top' ? 'translateY(-100%)' : ''}`
                    }}
                >
                    <span
                        data-te="true"
                        data-tc="Chat"
                        data-ta="Term toggle (close cross)"
                        id="term-close"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowTooltip(false);
                        }}
                        className="cursor-pointer absolute top-2 right-2 opacity-80 hover:opacity-100 z-50 w-10 h-10"
                    >
                        <img
                            src={`/assets/icons/close-cross.svg`}
                            alt="Close"
                            className="w-10"
                        />
                    </span>
                    <span className="term-definition pr-8 inline-block"><span className="font-bold">{term}</span> = {definitionEn} {showLocal && ` (${definition})`}</span>
                    {explanation && <span className="term-explanation block mt-2">{explanation}</span>}
                </span>
            )}
        </span>
    );
};

export default Term;