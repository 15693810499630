import { pdfjs } from 'react-pdf';

if (typeof Promise.withResolvers === 'undefined') {
    if (window)
        window.Promise.withResolvers = function () {
            let resolve, reject;
            const promise = new Promise((res, rej) => {
                resolve = res;
                reject = rej;
            });
            return { promise, resolve, reject };
        };
}

export const initPdfWorker = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
};