import React, { useEffect, useRef, useState, Suspense } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './views/Login';
import LoginCallback from './LoginCallback';
import { useEnv } from './providers/EnvContext';
import { useGetUserInfo } from './services/userApi';
import { removeOrigUrl, storeOrigUrl } from './utils/storeOrigUrl';
import KoneLoader from './components/KoneLoader';
import { trackUserEvent } from './services/analyticsEventService';
import Reset from './components/Reset';
import UserAdmin from './views/UserAdmin';
import { initPdfWorker } from './utils/pdfWorkerFactory';

if (process.env.NODE_ENV !== 'test') {
    initPdfWorker();
}

const Chat = React.lazy(() => import('./views/Chat'));

const AppRouting = () => {
    const navigate = useNavigate();
    const { idToken, accessToken, setUsername, setUid } = useEnv();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [initComplete, setInitComplete] = useState(false);
    const userInfoRef = useRef(false);

    const getUserInfo = useGetUserInfo();

    const getUserInfoAndInit = async () => {
        if (userInfoRef.current) return;
        userInfoRef.current = true;
        const userInfo = await getUserInfo();
        setUsername(userInfo.user || '');
        setUid(userInfo.uid || '');
        setIsAuthenticated(true);
        setInitComplete(true);
    }

    const getUserRole = () => {
        return sessionStorage.getItem('tai_technician') === 'true' ? 'Technician' : 'Operator';
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (sessionStorage.getItem('origUrl')) {
                const origUrl = sessionStorage.getItem('origUrl');
                removeOrigUrl();
                navigate(origUrl);
            } else if (new URL(window.location.href).pathname.indexOf('/login-callback') === 0) {
                navigate('/chat');
            }
        }
    }, [isAuthenticated]); // eslint-disable-line

    const loadGoogleTagManager = () => {
        const gtmId = sessionStorage.getItem('tai_gtmid');
        if (window.dataLayer || !gtmId) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            user_role: getUserRole()
        })

        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });

        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        document.head.appendChild(script);
    };

    const loadSurvicate = () => {
        const workspaceId = sessionStorage.getItem('tai_survicateWorkspace');
        if (!workspaceId) return;

        const script = document.createElement('script');
        script.innerHTML = `
        (function (w) {
          var s = document.createElement('script');
          s.src = '//survey.survicate.com/workspaces/${workspaceId}/web_surveys.js';
          s.async = true;
          var e = document.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(s, e);
        })(window._sva = window._sva || {});
      `;
        document.body.appendChild(script);
    }

    useEffect(() => {
        if (isAuthenticated) {
            loadGoogleTagManager();
            loadSurvicate();
        }
    }, [isAuthenticated]); // eslint-disable-line

    useEffect(() => {
        if (idToken && accessToken) {
            if (!userInfoRef.current) {
                getUserInfoAndInit();
            }
        } else {
            storeOrigUrl();
            setInitComplete(true);
        }
    }, [idToken, accessToken]); // eslint-disable-line

    useEffect(() => {
        const trackEventHandler = (event) => {
            const trackElement = event.target.closest('[data-te="true"]'); // track event

            if (trackElement) {
                const category = trackElement.getAttribute('data-tc'); // track category
                const action = trackElement.getAttribute('data-ta'); // track action
                trackUserEvent({ category, action });
            }
        };

        document.addEventListener('click', trackEventHandler);

        return () => {
            document.removeEventListener('click', trackEventHandler);
        };
    }, []);

    if (!initComplete) {
        return <div><KoneLoader /></div>;
    }

    return (
        <Routes>
            <Route
                path="/"
                element={isAuthenticated ? <Navigate to="/chat" /> : <Navigate to="/login" />}
            />
            <Route
                path="/login"
                element={<Login />}
            />
            <Route
                path="/reset"
                element={<Reset />}
            />
            <Route
                path="/chat/:cid?"
                element={
                    isAuthenticated ? (
                        <Suspense fallback={<div><KoneLoader /></div>}>
                            <Chat />
                        </Suspense>
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            />
            <Route path="/admin/users"
                element={
                    isAuthenticated ? (
                        <Suspense fallback={<div><KoneLoader /></div>}>
                            <UserAdmin />
                        </Suspense>
                    ) : (
                        <Navigate to="/login" />
                    )
                }
            />
            <Route path="*"
                element={isAuthenticated ? <Navigate to="/chat" /> : <Navigate to="/login" />}
            />
            <Route path="/login-callback" element={<LoginCallback />} />
        </Routes>
    );
};

export default AppRouting;
